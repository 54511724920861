<template>
  <div
    :style="{ 'background-image': `url(${backgroundImage})` }"
  >
    <div>
      <h1>
        <span>{{ $t('heading.join_now') }}</span>
        {{ $t('heading.and_instantly_access') }}
        <span>{{ $t('heading.millions') }}</span>
        {{ $t('heading.photos_videos_livestreams') }}
      </h1>

      <div class="join-button">
        {{ $t('button.join_now') }}
      </div>
    </div>
  </div>
</template>

<script>
import backgroundImage from './background.jpg';
import messages from './messages.json';

export default {
  name: 'TheJoinNowDefaultBanner',

  data: () => ({ backgroundImage }),

  i18n: { messages },
};
</script>

<style lang="less" scoped>
div {
  text-align: center;
}

h1 {
  color: white;
  margin: 0 10px 15px 10px;
  text-transform: uppercase;
  text-align: center;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  background: rgba(0, 0, 0, 0.15);
  padding: 4px;

  @media @phone-only {
    font-size: 12px;
  }

  a {
    color: white;
    text-decoration: none;
  }

  span {
    text-decoration: underline;
  }
}

.join-button {
  background-color: white;
  display: inline-block;
  font-weight: bold;
  padding: 10px 20px 10px 20px;
  text-transform: uppercase;

  @media @phone-only {
    font-size: 12px;
    padding: 10px 20px 10px 20px;
  }
}
</style>
