/**
 * Displays a button to launch the live stream viewer when
 * the user is currently live.
 */
<template>
  <OpenViewStreamDialog
    v-if="stream"
    :stream="stream"
  >
    <a class="icon-livenow live-on-sg">&nbsp;Live Now on SG</a>
  </OpenViewStreamDialog>
</template>

<script>

import { mapState } from 'vuex';

import OpenViewStreamDialog from 'main/components/OpenViewStreamDialog';
import Stream from 'main/models/Stream';

export default {
  name: 'UserLiveOnSgButton',

  components: {
    OpenViewStreamDialog,
  },

  props: {
    user_id: { // eslint-disable-line vue/prop-name-casing
      type: Number,
      required: true,
    },
  },

  computed: {
    stream() {
      return Stream.query().where('user_id', this.user_id).get().pop();
    },
    ...mapState({
      streams: (state) => state.live.streams,
    }),
  },
};
</script>

<style>
</style>
