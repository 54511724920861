<template>
  <div
    v-if="show"
    class="wrapper"
  >
    <a
      v-if="bannerType === 'tip-funding-offer'"
      @click.prevent="launchFundingOffer"
    >
      <TheTipFundingPromoBanner
        :funding-bonus-amount="fundingBonus?.available"
        :funding-bonus-min-fund="fundingBonus?.min"
      />
    </a>
    <a
      v-else
      :href="joinLink"
      :target="joinLinkTarget"
    >
      <TheJoinNowSpringBreakBanner
        v-if="bannerType === 'springbreak'"
      />
      <TheJoinNowDefaultBanner
        v-else
      />
    </a>

    <button
      class="icon-close"
      @click="close()"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import TheJoinNowDefaultBanner from './TheJoinNowDefaultBanner';
import TheJoinNowSpringBreakBanner from './TheJoinNowSpringBreakBanner';
import TheTipFundingPromoBanner from './TheTipFundingPromoBanner';
import messages from './messages.json';

/**
 * @displayName The Join Now Banner
 */
export default {
  name: 'TheJoinNowBanner',

  i18n: { messages },

  components: {
    TheJoinNowDefaultBanner,
    TheTipFundingPromoBanner,
    TheJoinNowSpringBreakBanner,
  },

  props: {
    /**
     * The target URL on the main site
     */
    url: {
      type: String,
      required: true,
    },

    /**
     * The target URL on the PWA/app.
     */
    appUrl: {
      type: String,
      default: null,
    },

    token: {
      type: String,
      default: null,
    },

    campaignName: {
      type: String,
      default: null,
    },
  },

  computed: {
    bannerType() {
      // only show the special tip funding banner to members
      // that qualify for it.
      const bonus = this.fundingBonus?.available || 0;
      if (bonus > 0) {
        return 'tip-funding-offer';
      }
      const { campaignName } = this;
      return campaignName === 'springbreak' ? campaignName : 'default';
    },

    show() {
      const { joinNowBannerClosed } = this.$store.getters;
      if (joinNowBannerClosed) {
        return false;
      }
      if (this.currentUser.is_authenticated) {
        return this.bannerType === 'tip-funding-offer';
      }
      return true;
    },

    joinLink() {
      const token = this.token || this.$t('tracking_token');
      if (this.isMobile && this.appUrl) {
        const url = new URL(this.appUrl);
        url.searchParams.append('token', token);
        return url.href;
      }

      return `${this.url}#~tt~${token}`;
    },

    joinLinkTarget() {
      return this.isMobile ? '_blank' : '';
    },

    ...mapGetters(['currentUser', 'fundingBonus']),
    ...mapGetters('browser', ['isMobile']),
  },

  methods: {
    launchFundingOffer() {
      window.location.hash = '#addmoney';
    },
    close() {
      this.$store.commit('setJoinNowBannerClosed', true);
    },
  },
};
</script>

<style lang="less" scoped>
.wrapper {
  padding-bottom: 15%;
  position: relative;
  min-height: 200px;

  @media @phone-only {
    min-height: 100px;
    padding-bottom: 32%;
  }

  > a, > a > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  a > * {
    align-items: center;
    background-size: cover;
    background-position: center center;
    display: flex;
    justify-content: center;
    cursor: pointer;
  }
}

button.icon-close {
  background-color: transparent;
  position: absolute;
  padding: 15px;
  top: 0px;
  right: 0px;

  @media @phone-only {
    padding: 8px;
  }
}
</style>
