<template>
  <div class="livechat">
    <div class="options-menu-container">
      <ul
        v-if="viewMenu"
        ref="menu"
        class="options-menu"
        tabindex="-1"
        :style="{ top: menuTop, left: menuLeft }"
        @blur="closeMenu"
      >
        <li><a @click="block()">Block user</a></li>
      </ul>
    </div>
    <div
      v-chat-scroll="{always: false, smooth: true}"
      class="comments"
      @scroll.passive="handleScroll"
    >
      <div
        v-for="(obj, index) in comments"
        :key="index"
        :item="obj"
        :class="obj.user_type"
        class="comment"
      >
        <a class="avatar">
          <img :src="obj.avatar">
        </a>
        <div class="comment-details">
          <div class="username-cog-split">
            <div class="username">
              <a
                :href="profileLink(obj)"
                target="_blank"
              >{{ obj.username }}:</a>
            </div>
            <span
              v-if="isStreaming || isStreamOwner"
              class="icon-cog"
              @click.prevent="toggleMenu($event, obj)"
            />
          </div>
          <!-- eslint-disable-next-line vue/no-v-html -->
          <p v-html="obj.comment" />
        </div>
      </div>
    </div>
    <div
      v-if="enabled"
      class="comment-form"
    >
      <form
        action="#"
        autocomplete="off"
        @submit.prevent="submitComment"
      >
        <input
          v-model="message"
          type="text"
          name="comment"
          placeholder="Write a comment..."
          autocomplete="off"
          @keyup="commentChanged"
        >
      </form>
    </div>
    <div class="new-messages-indicator-container">
      <NewMessagesIndicator
        :show="enabled && showNewMessagesIndicator"
        @show-new-messages="handleShowLatest"
      />
    </div>
  </div>
</template>

<script>
import Autolinker from 'autolinker';
import axios from 'axios';
import { v4 as uuid4 } from 'uuid';
import Vue from 'vue';
import VueChatScroll from 'vue-chat-scroll';
import { mapGetters, mapState } from 'vuex';

import NewMessagesIndicator from
  'main/components/NewMessagesIndicator/NewMessagesIndicator';

Vue.use(VueChatScroll);

/**
 * Live Chat
 * @displayName Live Chat
 */
export default {
  components: {
    NewMessagesIndicator,
  },
  props: {
    comment: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      comments: [],
      menuComment: {},
      menuLeft: '0px',
      menuTop: '0px',
      message: '',
      showNewMessagesIndicator: false,
      toggleTimeout: null,
      viewMenu: false,
    };
  },
  computed: {
    /* Chat is enabled if viewing or streaming */
    enabled() {
      return this.isViewing || this.isStreaming;
    },

    isStreamOwner() {
      if (!this.stream) {
        return false;
      }

      return this.stream.user_id === this.currentUser.id;
    },

    ...mapState({
      isViewing: (state) => state.live.isViewing,
      isStreaming: (state) => state.live.isStreaming,
      stream: (state) => state.live.currentStream,
    }),

    ...mapGetters([
      'currentUser',
    ]),
  },

  watch: {
    /**
     * Watch comment prop for changes and treat as a new comment.
     *
     * Note: timeout is used due to the smooth scrolling feature in
     * vue-chat-scroll. After the comment is added, there's a few ms
     * delay before the auto-scrolling is complete.
     */
    comment() {
      if (this.comments.length > 0) {
        const isDuplicate = this.comments.find(
          (comment) => comment.uuid === this.comment.uuid,
        );

        if (isDuplicate) {
          return;
        }
      }

      if (this.toggleTimeout) {
        clearTimeout(this.toggleTimeout);
      }

      this.addLatestComment();

      this.toggleTimeout = setTimeout(() => {
        this.showNewMessagesIndicator = !this.isNearBottom();
      }, 300);
    },
  },
  methods: {
    addLatestComment() {
      this.autolink(this.comment);
      this.comments.push(this.comment);
    },

    closeMenu() {
      this.viewMenu = false;
    },

    toggleMenu(e, comment) {
      if (this.viewMenu) {
        this.viewMenu = false;
        return;
      }

      this.menuComment = comment;
      this.viewMenu = true;

      this.$nextTick(() => {
        this.$refs.menu.focus();
        this.menuTop = `${
          e.target.offsetTop
          - e.target
            .parentElement
            .parentElement
            .parentElement
            .parentElement
            .scrollTop
          - 12
        }px`;
        this.menuLeft = `${e.target.offsetLeft - 205}px`;
      });
    },

    block() {
      if (this.currentUser.id === this.menuComment.user_id) {
        SG.userError('Can\'t block yourself');
        return;
      }
      this.removeUser(this.menuComment.user_id);
      axios.post('/api/following/block/', { user_id: this.menuComment.user_id })
        .then(() => {
          SG.userMessage(`You have blocked ${this.menuComment.username}`);
        });
      this.viewMenu = false;
    },

    autolink(comment) {
      comment.comment = Autolinker.link(comment.comment, {
        sanitizeHtml: true,
        twitter: false,
        phone: false,
        mention: 'twitter',
        replaceFn: (match) => {
          if (match.getType() === 'mention') {
            return (
              `<a href="/members/${match.getMention()}/" target="_blank">
                @${match.getMention()}
              </a>`
            );
          }
          return true;
        },
      });
    },

    profileLink(comment) {
      let link;

      if (comment.user_type === 'suicidegirl') {
        link = `/girls/${comment.username}/`;
      } else {
        link = `/members/${comment.username}/`;
      }

      return link;
    },

    commentChanged(e) {
      // Check for enter key with a non-empty message
      if (e.code !== 'Enter') {
        return;
      }

      this.submitComment();
    },

    submitComment() {
      if (this.message === '') {
        return;
      }

      const comment = {
        type: 'streamer.comment',
        avatar: this.currentUser.avatar,
        username: this.currentUser.username,
        user_id: this.currentUser.id,
        comment: this.message,
        user_type: this.currentUser.user_type,
        timestamp: new Date().getTime(),
        user: this.currentUser,
        uuid: uuid4(),
      };

      this.$emit('send', {
        msgtype: 'data.custom',
        to: ['viewer', 'streamer'],
        data: comment,
      });

      this.comments.push(comment);
      this.message = '';
    },

    removeUser(userId) {
      this.$emit('send', {
        msgtype: 'data.custom',
        to: ['viewer'],
        data: {
          type: 'streamer.remove_user',
          user_id: userId,
        },
      });
    },

    handleShowLatest() {
      const $comments = this.$el.querySelector('.comments');
      $comments.scrollTop = $comments.scrollHeight;
      this.showNewMessagesIndicator = false;
    },

    handleScroll() {
      if (this.isNearBottom()) {
        this.showNewMessagesIndicator = false;
      }
    },

    isNearBottom() {
      const $comments = this.$el.querySelector('.comments');
      return $comments.scrollHeight - $comments.scrollTop
        - $comments.clientHeight < 25;
    },
  },
};
</script>

<style scoped lang="less">
.livechat {
  background-color: @background-grey;
  display: flex;
  flex-direction: column;
}

.new-messages-indicator-container {
  position: relative;

  .new-messages-indicator {
    bottom: 75px;
    cursor: pointer;
    position: absolute;
    width: 100%;
  }
}

.comments {
  flex-grow: 1;
  height: 1px;
  overflow-y: scroll;
  padding: 0;
  position: relative;
}

.comment {
  border-bottom: 1px solid @background-shaded-grey;
  display: flex;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
  padding-bottom: 10px;

  &:first-child {
    margin-top: 0px;
  }

  &.staff {
    background-color: #e1f1db;
  }

  &.suicidegirl {
    background-color: #f9dbe2;
  }

  &.hopeful {
    background-color: #eae1ef;
  }

  &.photographer {
    background-color: #f0e9d6;
  }
}

.avatar {
  flex-grow: 0;
  margin-right: 20px;

  img {
    height: 40px;
    width: 40px;
  }
}

.comment-details {
  flex-grow: 1;
}

.username-cog-split {
  display: flex;
}

.username {
  flex-grow: 1;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.icon-cog {
  color: #9e9e9e;
  font-size: 14px;

  &:hover {
    color: #414141;
  }
}

.comment-form {
  border-top: 1px solid @background-shaded-grey;
  bottom: 0;
  flex-grow: 0;
  padding: 10px;
  width: 100%;

  input {
    width: 100%;
  }
}

.options-menu-container {
  position: relative;
  width: 100%;
}

.options-menu {
  background: #999999;
  border: 1px solid #BDBDBD;
  display: block;
  list-style: none;
  margin: 0;
  padding: 0;
  position: absolute;
  width: 200px;
  z-index: 999999;

  &:focus {
    outline: none;
  }

  li {
    margin: 0;

    a {
      background-color: #999999;
      color: @white;
      display: block;
      font-family: gotham_boldregular;
      font-size: 12px;
      height: 100%;
      letter-spacing: 1px;
      padding: 10px;
      text-align: center;
      text-transform: uppercase;
      width: 100%;

      &:hover {
        background: #000000;
        color: #de879e;
      }
    }

    &:last-child {
      &:after {
        content: '\0020';
        display: block;
        width: 0;
        height: 0;
        border: 0 none;
        border-style: solid;
        border-width: 5px 0px 5px 5px;
        border-color: transparent;
        border-left-color: #999999;
        position: absolute;
        right: -5px;
        top: 12px;
      }
    }
  }
}
</style>
