<template>
  <div
    class="live-send-tip-controls"
    :style="{width: `${width}px`}"
    :class="sizeClass"
    @click.prevent.stop=""
  >
    <div class="balance-amount-container">
      <div class="balance">
        <span>Your </span>Balance: <span>${{ formattedBalance }}</span>
      </div>
      <div class="amount">
        <select v-model="amount">
          <option value="1">
            TIP: $1
          </option>
          <option value="2">
            TIP: $2
          </option>
          <option value="5">
            TIP: $5
          </option>
          <option value="10">
            TIP: $10
          </option>
          <option value="15">
            TIP: $15
          </option>
        </select>
      </div>
    </div>
    <div class="privacy-container">
      <div class="privacy-heading">
        Tip Privacy
      </div>
      <select v-model="displayOnSG">
        <option value="yes">
          Display on SG
        </option>
        <option value="no">
          Make anonymous
        </option>
      </select>
    </div>
    <div class="tip-model">
      <button
        :disabled="insufficientFunds"
        @click.prevent.stop="sendTip"
      >
        Tip <span>Model</span>
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

/**
 * Live Send Tip Controls
 * @displayName Live Send Tip Controls
 */
export default {
  props: {
    /**
     * Width of the controls.
     */
    width: {
      type: Number,
      default: 575,
    },
  },

  data() {
    return {
      amount: 1.00,
      sizeClass: '',
      displayOnSG: 'yes',
    };
  },

  computed: {
    insufficientFunds() {
      return this.balance < this.amount;
    },
    ...mapGetters(['balance', 'formattedBalance']),
    ...mapState({
      stream: (state) => state.live.currentStream,
    }),
  },

  watch: {
    width() {
      this.setSizeClass();
    },
  },

  mounted() {
    this.$store.dispatch('refreshBalance');
    this.setSizeClass();
  },

  methods: {
    setSizeClass() {
      let sizeClass;

      if (this.width < 150) {
        sizeClass = 'supermicro';
      } else if (this.width < 250) {
        sizeClass = 'micro';
      } else if (this.width < 400) {
        sizeClass = 'tiny';
      } else if (this.width < 575) {
        sizeClass = 'small';
      } else {
        sizeClass = '';
      }

      this.sizeClass = sizeClass;
    },

    sendTip() {
      const payload = {
        amount: this.amount,
        public: this.displayOnSG === 'yes',
        text: '',
      };

      this.$store.dispatch('tip', {
        payload,
        url: this.stream.tips_url,
      })
        .then((tip) => {
          /**
           * Tip was sent successfully.
           */
          this.$emit('tip-sent');
          const { username } = this.stream.user;
          SG.userMessage(`You tipped ${username} $${tip.amount}`);
        })
        .catch((error) => {
          let message = 'Error sending tip';

          if (error.response.status === 400) {
            if (
              error.response.data.non_field_errors
              && error.response.data.non_field_errors[0] === 'Inactive models'
            ) {
              message = 'User is not tippable';
            } else if (
              error.response.data.amount
              && error.response.data.amount[0] === 'Insufficient balance'
            ) {
              message = 'Insufficient balance';
            }
          }

          SG.userError(message);
        });
    },
  },
};
</script>

<style lang="scss">
@import '~main/styles/select.scss';

.live-send-tip-controls {
  background-color: rgba(20, 20, 20, .8);
  border-radius: 7px;
  color: #ffffff;
  display: flex;
  height: 60px;
  font-family: gotham_boldregular;
  font-size: 10px;
  letter-spacing: 1px;
  text-transform: uppercase;

  > div {
    flex-grow: 1;
    flex-basis: 33%;
  }

  select {
    appearance: none;
    background-color: transparent;
    background-image: $select-arrow;
    background-repeat: no-repeat, repeat;
    background-position: right .7em top 50%, 0 0;
    background-size: .65em auto, 100%;
    border: none;
    color: #ffffff;
    font-family: gotham_boldregular;
    font-size: 12px;
    letter-spacing: 1px;
    margin-top: 8px;
    text-transform: uppercase;
    width: 100%;
  }

  .balance-amount-container {
    border-right: 1px solid #666666;
    padding: 10px;
  }

  .balance {
    color: #666666;
    font-family: gotham_boldregular;
    letter-spacing: 1;
    text-transform: uppercase;
  }

  .amount {
    font-size: 12px;
  }

  .privacy-container {
    border-right: 1px solid #666666;
    font-family: gotham_boldregular;
    height: 100%;
    padding: 10px;
    text-transform: uppercase;

    .privacy-heading {
      color: #666666;
      font-size: 10px;
    }
  }

  .tip-model {
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 10px;

    button {
      background-color: #547b00;
      border-radius: 20px;
      color: #ffffff;
      font-family: gotham_boldregular;
      font-size: 11px;
      letter-spacing: 2px;
      padding: 8px 20px 8px 20px;
      text-transform: uppercase;

      &:disabled {
        background-color: #414141;
      }
    }
  }

  &.small {
    font-size: 8px;
    height: 50px;

    .balance-amount-container {
      padding: 8px;
    }

    .balance {
      :first-child {
        display: none;
      }
    }

    .amount {
      font-size: 10px;
    }

    select {
      font-size: 10px;
    }

    .privacy-container {
      padding: 8px;

      .privacy-heading {
        font-size: 8px;
      }
    }

    .tip-model {
      padding: 8px;

      button {
        font-size: 8px;
      }
    }
  }

  &.tiny {
    font-size: 7px;
    height: 50px;

    .balance-amount-container {
      padding: 7px;
    }

    .balance {
      :first-child {
        display: none;
      }
    }

    .amount {
      font-size: 8px;
    }

    select {
      font-size: 8px;
    }

    .privacy-container {
      padding: 7px;

      .privacy-heading {
        font-size: 7px;
      }
    }

    .tip-model {
      padding: 7px;

      button {
        font-size: 7px;

        span {
          display: none;
        }
      }
    }
  }

  &.micro {
    font-size: 9px;
    height: 125px;
    flex-direction: column;

    .balance-amount-container {
      border-right: initial;
      padding: 5px;
      text-align: center;
    }

    .privacy-container {
      border-right: initial;
    }

    .amount {
      font-size: 10px;
    }

    select {
      font-size: 10px;
      margin-top: 5px;
    }

    .privacy-container {
      padding: 5px;
      text-align: center;

      .privacy-heading {
        font-size: 9px;
      }
    }

    .tip-model {
      padding: 7px;

      button {
        font-size: 9px;
        padding: 5px 10px 5px 10px;
      }
    }
  }

  &.supermicro {
    font-size: 7px;
    height: 125px;
    flex-direction: column;

    .balance-amount-container {
      border-right: initial;
      padding: 5px;
      text-align: center;
    }

    .privacy-container {
      border-right: initial;
    }

    .amount {
      font-size: 8px;
    }

    select {
      font-size: 8px;
      margin-top: 5px;
    }

    .privacy-container {
      padding: 5px;
      text-align: center;

      .privacy-heading {
        font-size: 7px;
      }
    }

    .tip-model {
      padding: 7px;

      button {
        font-size: 7px;
        padding: 5px 10px 5px 10px;
      }
    }
  }
}
</style>
