<template>
  <modal>
    <div
      slot="container"
      class="modal-container"
      :style="{ width: modalWidth, height: modalHeight }"
      :class="{ streaming: streaming }"
    >
      <div
        v-if="!supported"
        class="not-supported"
      >
        <div class="question-mark">
          !
        </div>
        <h1>Unsupported Browser</h1>
        <p>To go live on SG, your browser must support WebRTC.</p>
        <button @click="CLOSE_STREAM_DIALOG">
          OK
        </button>
      </div>

      <Split
        v-if="supported"
        :direction="splitDirection"
        :style="{ height: modalHeight }"
        :gutter-size="gutterSize"
      >
        <SplitArea
          :size="splitPercentVideoFeed"
        >
          <div
            class="video-feed-container"
          >
            <button
              v-if="!streaming"
              class="icon-close"
              @click="close()"
            />
            <publisherVideoFeed
              @initializing="handleInitializing"
              @started="handleStreamingStarted"
              @ended="handleStreamingEnded"
              @received-custom-data="handleCustomData"
              @tick="handleTick"
            />
          </div>
        </SplitArea>
        <SplitArea
          :size="splitPercentLiveChat"
        >
          <div class="split-area-2">
            <LiveRoomMembers
              :members="uniqueViewers"
            />
            <LiveChat
              v-if="streaming"
              class="livechat"
              :comment="latestComment"
              @send="sendMessage"
            />
          </div>
        </SplitArea>
      </Split>
    </div>
  </modal>
</template>

<script>

import { find } from 'lodash-es';
import Vue from 'vue';
import VueSplit from 'vue-split-panel';
import { mapMutations, mapState } from 'vuex';

import LiveChat from 'main/components/LiveChat/LiveChat';
import LiveRoomMembers from 'main/components/LiveRoomMembers/LiveRoomMembers';
import Modal from 'main/components/Modal';
import PublisherVideoFeed from 'main/components/PublisherVideoFeed';
import LiveStreamModalResizer from 'main/mixins/LiveStreamModalResizer';
import {
  ORIENTATION_PORTRAIT,
  ORIENTATION_LANDSCAPE,
} from 'main/store/modules/browser';

const LANDSCAPE_LANDSCAPE = 'landscape_landscape';
const LANDSCAPE_PORTRAIT = 'landscape_portrait';
const PORTRAIT_PORTRAIT = 'portrait_portrait';
const PORTRAIT_LANDSCAPE = 'portrait_landscape';
const SPLIT_HORIZONTAL = 'horizontal';
const SPLIT_VERTICAL = 'vertical';

Vue.use(VueSplit);

export default {
  components: {
    LiveChat,
    LiveRoomMembers,
    Modal,
    PublisherVideoFeed,
  },

  mixins: [LiveStreamModalResizer],

  data() {
    return {
      lastTick: null,
      latestComment: null,
      modalHeight: '480px',
      modalWidth: '640px',
      splitDirection: 'horizontal',
      splitPercentLiveChat: 0,
      splitPercentVideoFeed: 100,
      streaming: false,
      streamModule: null,
      videoWidth: '640px',
      videoHeight: '480px',
      viewers: {},
    };
  },

  computed: {
    gutterSize() {
      return this.streaming ? 10 : 0;
    },

    /**
     * The unique viewers watching the stream.
     *
     * Viewers in data contains all viewer instances, even with the same user
     * watching in multiple tabs/browsers. This filters the full list, to
     * only contain the information for each unique user.
     */
    uniqueViewers() {
      const unique = [];
      const viewers = Object.values(this.viewers);

      viewers.forEach((viewer) => {
        const exists = Boolean(find(unique, { username: viewer.username }));
        if (!exists && (this.lastTick - viewer.lastUpdate) < 60) {
          unique.push(viewer);
        }
      });

      return unique;
    },
    ...mapState({
      localStream: (state) => state.live.mediaStream,
      supported: (state) => state.browser.supportsWebRtc,
      nativeVideoSettings: (state) => state.live.nativeVideoSettings,
    }),
  },

  watch: {
    orientation() {
      SG.debug('Detected orientation change, resizing modal');
      this.resizeModal();
    },
    nativeVideoSettings() {
      this.resizeModal();
    },
  },

  mounted() {
    window.theStreamDialog = this; // Temp for console debugging

    if (window.innerWidth < 640 || window.innerHeight < 480) {
      this.videoHeight = `${window.innerHeight}px'`;
      this.videoWidth = `${window.innerWidth}px`;
    }

    this.modalWidth = window.innerWidth >= 640
      ? '640px' : `${window.innerWidth}px`;
    this.modalHeight = window.innerHeight >= 480
      ? '480px' : `${window.innerHeight}px`;
  },

  methods: {
    ...mapMutations([
      'CLOSE_STREAM_DIALOG',
    ]),

    handleInitializing(streamModule) {
      this.streamModule = streamModule;
    },

    handleStreamingStarted() {
      this.streaming = true;
      this.splitPercentVideoFeed = 66;
      this.splitPercentLiveChat = 34;

      setTimeout(this.resizeModal.bind(this), 1000);
      setTimeout(this.resizeModal.bind(this), 1500);
    },

    handleStreamingEnded() {
      this.streaming = false;
      this.CLOSE_STREAM_DIALOG();
    },

    close() {
      this.streamModule.cleanUp();
      this.CLOSE_STREAM_DIALOG();
    },

    resizeModal() {
      if (!this.localStream) {
        SG.debug('Resizing modal: No local stream yet.');
        return;
      }
      if (this.streaming) {
        this.resizeForOrientations();
      } else {
        switch (this.orientations()) {
          case LANDSCAPE_LANDSCAPE:
            this.resizeModalLandscapeLandscapePreview();
            break;
          case LANDSCAPE_PORTRAIT:
            this.resizeModalLandscapePortraitPreview();
            break;
          case PORTRAIT_PORTRAIT:
            this.resizeModalPortraitPortraitPreview();
            break;
          case PORTRAIT_LANDSCAPE:
            this.resizeModalPortraitLandscapePreview();
            break;
          default:
            SG.debug('unkwon orientation');
            SG.debug(this.orientations());
        }
      }
    },

    resizeModalLandscapeLandscapePreview() {
      if (window.innerWidth < 600 || window.innerHeight < 480) {
        this.resizeModalLandscapeLandscapePreviewPhone();
      } else {
        this.resizeModalLandscapeLandscapePreviewDesktop();
      }
    },

    resizeModalLandscapeLandscapePreviewPhone() {
      let modalWidth = window.innerWidth * 0.95;
      let modalHeight = modalWidth / this.aspectRatio();

      if (modalHeight > window.innerHeight) {
        modalHeight = window.innerHeight * 0.95;
        modalWidth = modalHeight * this.aspectRatio();
      }

      this.modalHeight = `${modalHeight}px`;
      this.modalWidth = `${modalWidth}px`;

      this.splitDirection = SPLIT_HORIZONTAL;
      this.splitPercentVideoFeed = 100;
      this.splitPercentLiveChat = 0;
    },

    resizeModalLandscapeLandscapePreviewDesktop() {
      let modalWidth = window.innerWidth * 0.95;
      let modalHeight = modalWidth / this.aspectRatio();

      if (modalHeight > window.innerHeight) {
        modalHeight = window.innerHeight * 0.95;
        modalWidth = modalHeight * this.aspectRatio();
      }

      this.modalHeight = `${modalHeight}px`;
      this.modalWidth = `${modalWidth}px`;

      this.splitDirection = SPLIT_HORIZONTAL;
      this.splitPercentVideoFeed = 100;
      this.splitPercentLiveChat = 0;
    },

    resizeModalLandscapePortraitPreview() {
      if (window.innerWidth < 600 || window.innerHeight < 480) {
        this.resizeModalLandscapePortraitPreviewPhone();
      } else {
        this.resizeModalLandscapePortraitPreviewDesktop();
      }
    },

    resizeModalLandscapePortraitPreviewPhone() {
    },

    resizeModalLandscapePortraitPreviewDesktop() {
      SG.debug('Resize landscape portrait, preview, desktop');
    },

    resizeModalPortraitPortraitPreview() {
      if (window.innerWidth < 600 || window.innerHeight < 480) {
        this.resizeModalPortraitPortraitPreviewPhone();
      } else {
        this.resizeModalPortraitPortraitPreviewDesktop();
      }
    },

    resizeModalPortraitPortraitPreviewPhone() {
      let modalWidth = window.innerWidth * 0.95;
      let modalHeight = modalWidth / this.aspectRatio();

      if (modalHeight > window.innerHeight) {
        modalHeight = window.innerHeight * 0.95;
        modalWidth = modalHeight * this.aspectRatio();
      }

      this.modalHeight = `${modalHeight}px`;
      this.modalWidth = `${modalWidth}px`;

      this.splitDirection = SPLIT_VERTICAL;
      this.splitPercentVideoFeed = 100;
      this.splitPercentLiveChat = 0;
    },

    resizeModalPortraitPortraitPreviewDesktop() {
      SG.debug('Resize portrait portrait, preview, desktop');

      let modalWidth = window.innerWidth * 0.95;
      let modalHeight = modalWidth / this.aspectRatio();

      if (modalHeight > window.innerHeight) {
        modalHeight = window.innerHeight * 0.95;
        modalWidth = modalHeight * this.aspectRatio();
      }

      this.modalHeight = `${modalHeight}px`;
      this.modalWidth = `${modalWidth}px`;

      this.splitDirection = SPLIT_HORIZONTAL;
      this.splitPercentVideoFeed = 100;
      this.splitPercentLiveChat = 0;
    },

    resizeModalPortraitLandscapePreview() {
      if (window.innerWidth < 600 || window.innerHeight < 480) {
        this.resizeModalPortraitLandscapePreviewPhone();
      } else {
        this.resizeModalPortraitLandscapePreviewDesktop();
      }
    },

    resizeModalPortraitLandscapePreviewPhone() {
    },

    resizeModalPortraitLandscapePreviewDesktop() {
      SG.debug('Resize portrait landscape, preview, desktop');

      let modalWidth = window.innerWidth * 0.95;
      let modalHeight = modalWidth / this.aspectRatio();

      if (modalHeight > window.innerHeight) {
        modalHeight = window.innerHeight * 0.95;
        modalWidth = modalHeight * this.aspectRatio();
      }

      this.modalHeight = `${modalHeight}px`;
      this.modalWidth = `${modalWidth}px`;

      this.splitDirection = SPLIT_HORIZONTAL;
      this.splitPercentVideoFeed = 100;
      this.splitPercentLiveChat = 0;
    },

    videoOrientation() {
      if (!this.nativeVideoWidth() || !this.nativeVideoHeight()) {
        return 'default';
      }
      return this.nativeVideoWidth() / this.nativeVideoHeight() > 1
        ? ORIENTATION_LANDSCAPE : ORIENTATION_PORTRAIT;
    },

    aspectRatio() {
      return this.nativeVideoWidth() / this.nativeVideoHeight();
    },

    nativeVideoWidth() {
      const { width } = this.nativeVideoSettings;
      SG.debug(`Native width: ${width}`);
      return width;
    },

    nativeVideoHeight() {
      const { height } = this.nativeVideoSettings;
      SG.debug(`Native height: ${height}`);
      return height;
    },

    sendMessage(message) {
      this.streamModule.sendCustomMessage(message);
    },

    /**
     * Handle incoming custom data sent over the websocket channel with
     * all stream participants via the Janus server.
     */
    handleCustomData(message) {
      const handlers = {
        'streamer.comment': this.handleComment,
        'viewer.comment': this.handleComment,
        'viewer.joined': this.handleViewerJoined,
        'viewer.left': this.handleViewerLeft,
        'viewer.ping': this.handleViewerPing,
      };

      if (Object.prototype.hasOwnProperty.call(handlers, message.type)) {
        handlers[message.type].call(this, message);
      }
    },

    /**
     * Handle comment messages.
     *
     * These are comments from both viewers, and the streamer him or herself.
     */
    handleComment(message) {
      this.$emit('received-comment', message);
      this.latestComment = message;
    },

    /**
     * Handle viewer joined messages.
     *
     * These are sent by the viewer when they join the stream.
     */
    handleViewerJoined(message) {
      this.$set(this.viewers, message.viewerToken, {
        username: message.username,
        avatar: message.avatar,
        lastUpdate: Math.floor(new Date().getTime() / 1000),
      });
    },

    /**
     * Handle viewer left messages.
     *
     * These are sent by the viewer when they explicitly close the stream.
     */
    handleViewerLeft(message) {
      this.$delete(this.viewers, message.viewerToken);
    },

    /**
     * Handle viewer ping messages.
     *
     * These are sent by the viewer periodically to let the streamer
     * know they are still watching.
     */
    handleViewerPing(message) {
      try {
        this.viewers[message.viewerToken].lastUpdate = Math.floor(
          new Date().getTime() / 1000,
        );
      } catch (error) {
        SG.debug('Unrecognized viewer');
      }
    },

    /**
     * Handle stream ticks.
     *
     * This is being used as a way to avoid setting and managing a timeout to
     * remove unresponsive viewers from unique viewers.
     */
    handleTick(tick) {
      this.lastTick = tick;
    },
  },
};
</script>

<style scoped lang="less">
.modal-container {
  background-color: #2c2c2c;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
  font-family: Helvetica, Arial, sans-serif;
  height: 500px;
  margin: 0px auto;
  padding: 0;
  position: relative;
  transition: all .3s ease;
  width: 800px;

  .split-area-2 {
    display: flex;
    flex-direction: column;
    height: 100%;

    /deep/ .members {
      z-index: 10001;
    }
  }

  .livechat {
    position: initial;
    flex-grow: 1;
    width: 100%;
    z-index: 10000;
  }

  &.fullscreen {
    height: 100%;
    width: 100%;
  }

  @media @phone-only {
    width: 100%;
  }

  .icon-close {
    left: 0;
    position: absolute;
    z-index: 101;
  }

  .btn {
    border-radius: 25px;
  }

  .live-icon {
    position: absolute;
    left: 10px;
    top: 10px;
  }
}

.video-feed-container {
  height: 100%;
  overflow: hidden;
  width: 100%;
}

.not-supported {
  color: @white;
  font-family: gotham_bookregular;
  font-size: 14px;
  padding-left: 25px;
  padding-right: 25px;
  text-align: center;

  .question-mark {
    color: #cfcfcf;
    font-size: 55px;
    padding-bottom: 15px;
    padding-top: 25px;
  }

  button {
    margin-top: 10px;
  }
}
</style>
