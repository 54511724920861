<template>
  <modal>
    <div
      slot="container"
      class="modal-container"
      :style="{ width: modalWidth, height: modalHeight }"
      :class="{
        streaming: streaming,
        fullscreen: fullscreen,
        'w-portrait': orientation == 'portrait',
        'w-landscape': orientation == 'landscape'
      }"
    >
      <Split
        :direction="splitDirection"
        :style="{ height: modalHeight }"
        :gutter-size="gutterSize"
        @onDrag="onDrag"
      >
        <SplitArea
          :size="splitPercentVideoFeed"
        >
          <div
            class="video-feed-container"
          >
            <VideoFeed
              :small-desktop="smallDesktop"
              :last-resize="lastResize"
              @loadedmetadata="loadMetadata"
              @initializing="handleInitializing"
              @initialized="resizeModal"
              @started="handleStart"
              @received-custom-data="handleCustomData"
              @toggled-fullscreen="handleToggleFullscreen"
              @calculated-width="handleVideoWidthUpdate"
            />

            <!-- iPad view -->
            <div
              class="user-info"
              :style="{ width: userInfoWidth }"
            >
              <h2 class="decorated">
                {{ username }}
              </h2>
            </div>
          </div>
        </SplitArea>
        <SplitArea
          :size="splitPercentLiveChat"
        >
          <div class="split-area-2">
            <TheViewStreamDialogFollow
              v-if="stream && stream.user"
              :stream="stream"
              :class="{ narrow: narrowChat }"
            />

            <LiveChat
              class="livechat"
              :comment="latestComment"
              @send="sendMessage"
            />
          </div>
        </SplitArea>
      </Split>
    </div>
  </modal>
</template>

<script>
import { bind, debounce } from 'lodash-es';
import Vue from 'vue';
import VueSplit from 'vue-split-panel';
import { mapMutations, mapState } from 'vuex';

import LiveChat from './LiveChat/LiveChat';
import Modal from './Modal.vue';
import TheViewStreamDialogFollow from './TheViewStreamDialogFollow.vue';
import VideoFeed from './VideoFeed.vue';

import LiveStreamModalResizer, { SPLIT_HORIZONTAL }
  from 'main/mixins/LiveStreamModalResizer';
import {
  ORIENTATION_PORTRAIT,
  ORIENTATION_LANDSCAPE,
} from 'main/store/modules/browser';

Vue.use(VueSplit);

export default {
  components: {
    Modal,
    VideoFeed,
    LiveChat,
    TheViewStreamDialogFollow,
  },
  mixins: [LiveStreamModalResizer],
  data() {
    return {
      fullscreen: false,
      gutterSize: 5,
      lastResize: null,
      latestComment: null,
      liveChatWidth: null,
      liveChatHeight: null,
      modalHeight: '0px',
      modalWidth: '0px',
      narrowChat: false,
      nativeVideoWidth: null,
      nativeVideoHeight: null,
      windowOrientation: 'default',
      smallDesktop: false,
      splitDirection: SPLIT_HORIZONTAL,
      splitPercentLiveChat: 34,
      splitPercentVideoFeed: 64,
      userInfoWidth: '0',
      username: null,
      viewModule: null,
    };
  },
  computed: {
    ...mapState({
      stream: (state) => state.live.currentStream,
      streaming: (state) => state.live.isViewing,
    }),
  },
  mounted() {
    this.username = this.stream.user.username;
  },
  created() {
    window.addEventListener('resize', bind(this.handleResizeEvent, this));
  },
  destroyed() {
    window.removeEventListener('resize', bind(this.handleResizeEvent, this));
  },
  methods: {
    ...mapMutations([
      'CLOSE_VIEW_STREAM_DIALOG',
    ]),

    aspectRatio() {
      return this.nativeVideoWidth / this.nativeVideoHeight;
    },

    videoOrientation() {
      if (!this.nativeVideoWidth || !this.nativeVideoHeight) {
        return 'default';
      }
      return this.nativeVideoWidth / this.nativeVideoHeight > 1
        ? ORIENTATION_LANDSCAPE : ORIENTATION_PORTRAIT;
    },

    handleInitializing(viewModule) {
      this.viewModule = viewModule;
    },

    handleStart() {
      setTimeout(() => {
        this.resizeModal();
      }, 750);
    },

    onDrag() {
      this.lastResize = new Date();
      this.updateNarrowChat();
    },

    handleResizeEvent: debounce(function debounceResizeModal() {
      this.resizeModal();
    }, 150),

    resizeModal() {
      if (!this.nativeVideoWidth || !this.nativeVideoHeight) {
        return;
      }

      this.resizeForOrientations();

      // TODO: fix the following.
      this.smallDesktop = (
        window.innerWidth > 600
        && this.nativeVideoWidth < 400
      );
      this.lastResize = new Date();
    },

    handleToggleFullscreen(fullscreen) {
      SG.debug('Handling fullscreen toggle');
      this.fullscreen = fullscreen;
    },

    handleVideoWidthUpdate(width) {
      const totalWidth = this.$el
        .querySelector('.video-feed-container')
        .clientWidth;
      const remaining = totalWidth - width;
      this.userInfoWidth = `${remaining}px`;
    },

    updateNarrowChat() {
      if (this.splitDirection !== SPLIT_HORIZONTAL) {
        this.narrowChat = false;
        return;
      }

      if (!this.$el) {
        this.narrowChat = false;
        return;
      }

      const totalWidth = this.$el
        .querySelector('.modal-container')
        .clientWidth;

      if (!totalWidth) {
        this.narrowChat = false;
        return;
      }

      const liveChatWidth = this.$el.querySelector('.livechat').clientWidth;

      if (liveChatWidth < 200) {
        this.narrowChat = true;
      } else {
        this.narrowChat = false;
      }
    },

    loadMetadata(metadata) {
      this.nativeVideoWidth = metadata.nativeVideoWidth;
      this.nativeVideoHeight = metadata.nativeVideoHeight;
      this.resizeModal();
    },

    sendMessage(message) {
      this.viewModule.sendCustomMessage(message);
    },

    handleCustomData(message) {
      if (
        message.type === 'streamer.comment'
        || message.type === 'viewer.comment'
      ) {
        this.$emit('received-comment', message);
        this.latestComment = message;
      }
    },
  },
};
</script>

<style scoped lang="less">
.modal-container {
  background-color: #2c2c2c;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
  font-family: Helvetica, Arial, sans-serif;
  height: 500px;
  margin: 0px auto;
  padding: 0;
  position: relative;
  transition: all .3s ease;
  width: 800px;

  .split-area-2 {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .livechat {
    position: initial;
    flex-grow: 1;
    width: 100%;
  }

  &.fullscreen {
    height: 100%;
    width: 100%;
  }

  .btn {
    border-radius: 25px;
  }
}

.video-feed-container {
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.user-info {
  color: @white;
  display: none;
  overflow: hidden;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;

  @media @phoneLandscape {
    display: block;
  }

  h2.decorated {
    display: none;
    font-size: 22px;
    left: 50%;
    letter-spacing: 2px;
    margin: 0;
    position: absolute;
    text-align: center;
    text-transform: uppercase;
    top: 50%;
    transform: translate(-50%,-50%);

    @media @phoneLandscape {
      display: block;
    }

    &:before {
      display: block;
      font-size: 20px;
      margin-bottom: 10px;
      position: static;
    }
    &:after {
      display: block;
      font-size: 20px;
      margin-top: 10px;
      position: static;
    }
  }
}
</style>
