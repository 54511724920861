import VueI18n from 'vue-i18n';
import VuePluralize from 'vue-pluralize';
import VueLogger from 'vuejs-logger';
import Vuex from 'vuex';

export default {};

/**
 * Loads all app plugins / dependencies
 */
export function loadPlugins(Vue) {
  Vue.use(VueI18n);
  Vue.use(VuePluralize);
  Vue.use(Vuex);

  Vue.use(VueLogger, {
    isEnabled: true,
    logLevel: 'debug',
    stringifyArguments: false,
    showMethodName: true,
    separator: '|',
    showConsoleColors: true,
  });

  const i18n = new VueI18n({
    locale: navigator.language,
  });

  return { i18n };
}
