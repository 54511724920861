var render = function render(){var _vm=this,_c=_vm._self._c;return _c('fullscreen',{ref:"fullscreen",staticClass:"fs-container",on:{"change":_vm.fullscreenChange}},[_c('div',{staticClass:"video-feed",class:{
      fullscreen: _vm.fullscreen,
      'supermicro': _vm.supermicro,
      'micro': _vm.micro,
      'tiny': _vm.tiny,
      'small-desktop': _vm.smallDesktop,
    },on:{"click":_vm.handleUnhandledClick}},[_c('div',{class:{ streaming: _vm.viewing },attrs:{"id":"videowrap"}},[_c('video',{staticClass:"rounded centered",attrs:{"id":"myvideo","autoplay":"","playsinline":""},on:{"loadedmetadata":_vm.loadMetaData}}),_vm._v(" "),(!_vm.viewing)?_c('div',{staticClass:"ended"},[_vm._v("\n        The live stream has ended\n      ")]):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"actions",style:({ width: _vm.scaledVideoWidth })},[(_vm.showActionIcons && _vm.viewing)?_c('button',{staticClass:"fullscreen icon-fullscreen-v2",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.toggleFullscreen()}}}):_vm._e(),_vm._v(" "),(_vm.showActionIcons)?_c('button',{staticClass:"icon-close",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.stop()}}}):_vm._e(),_vm._v(" "),(_vm.showActionIcons && _vm.viewing)?_c('button',{staticClass:"mute",class:{ muted: _vm.muted },on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.toggleMute()}}},[_vm._v("\n        Mute\n      ")]):_vm._e(),_vm._v(" "),(
          _vm.showActionIcons && _vm.viewing && !_vm.watchingOwnStream
            && _vm.stream.tippable && !_vm.tooSmallForTipping
        )?_c('button',{staticClass:"tip",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.toggleTipControls.apply(null, arguments)}}}):_vm._e(),_vm._v(" "),(
          _vm.viewing && _vm.showTipControls && !_vm.watchingOwnStream
            && _vm.stream.tippable && !_vm.tooSmallForTipping
        )?_c('LiveSendTipControls',{style:({ bottom: `${_vm.sendTipControlsBottom}px` }),attrs:{"width":_vm.sendTipControlsWidth},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();},"tip-sent":function($event){_vm.showTipControls = false}}}):_vm._e()],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }