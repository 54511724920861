import { mapGetters } from 'vuex';

import {
  ORIENTATION_PORTRAIT,
  ORIENTATION_LANDSCAPE,
} from 'main/store/modules/browser';

export const LANDSCAPE_LANDSCAPE = 'landscape_landscape';
export const LANDSCAPE_PORTRAIT = 'landscape_portrait';
export const PORTRAIT_PORTRAIT = 'portrait_portrait';
export const PORTRAIT_LANDSCAPE = 'portrait_landscape';

export const SPLIT_HORIZONTAL = 'horizontal';
export const SPLIT_VERTICAL = 'vertical';

const DEFAULT_LIVE_CHAT_WIDTH = 275;

export default {
  computed: {
    ...mapGetters('browser', ['orientation']),
  },

  methods: {
    resizeForOrientations() {
      switch (this.orientations()) {
        case LANDSCAPE_LANDSCAPE:
          this.resizeModalLandscapeLandscape();
          break;
        case LANDSCAPE_PORTRAIT:
          this.resizeModalLandscapePortrait();
          break;
        case PORTRAIT_PORTRAIT:
          this.resizeModalPortraitPortrait();
          break;
        case PORTRAIT_LANDSCAPE:
          this.resizeModalPortraitLandscape();
          break;
        default:
      }
    },

    orientations() {
      if (this.orientation === ORIENTATION_LANDSCAPE
        && this.videoOrientation() === ORIENTATION_LANDSCAPE) {
        return LANDSCAPE_LANDSCAPE;
      }
      if (this.orientation === ORIENTATION_LANDSCAPE
        && this.videoOrientation() === ORIENTATION_PORTRAIT) {
        return LANDSCAPE_PORTRAIT;
      }
      if (this.orientation === ORIENTATION_PORTRAIT
        && this.videoOrientation() === ORIENTATION_PORTRAIT) {
        return PORTRAIT_PORTRAIT;
      }
      if (this.orientation === ORIENTATION_PORTRAIT
        && this.videoOrientation() === ORIENTATION_LANDSCAPE) {
        return PORTRAIT_LANDSCAPE;
      }
      return 'unknown';
    },

    resizeModalLandscapeLandscape() {
      if (window.innerWidth >= 600 && window.innerWidth <= 1024) {
        this.resizeModalLandscapeLandscapePhoneLandscape();
      } else if (window.innerWidth < 600 || window.innerHeight < 480) {
        this.resizeModalLandscapeLandscapePhone();
      } else {
        this.resizeModalLandscapeLandscapeDesktop();
      }
    },

    resizeModalLandscapeLandscapePhone() {
      SG.debug('Resizing landscape landscape, phone');
      this.modalWidth = `${window.innerWidth}px`;
      this.modalHeight = `${window.innerHeight}px`;
      this.splitDirection = SPLIT_VERTICAL;
      this.splitPercentVideoFeed = 66;
      this.splitPercentLiveChat = 34;
    },

    resizeModalLandscapeLandscapePhoneLandscape() {
      SG.debug('Resize landscape landscape, streaming, phone landscape');
      this.modalWidth = `${window.innerWidth}px`;
      this.modalHeight = `${window.innerHeight}px`;
      this.splitDirection = SPLIT_VERTICAL;
      this.splitPercentVideoFeed = 50;
      this.splitPercentLiveChat = 50;
    },

    resizeModalLandscapeLandscapeDesktop() {
      SG.debug('Resize landscape landscape, desktop');

      let modalWidth;
      let modalHeight;
      let videoWidth;
      let videoHeight;

      modalWidth = window.innerWidth * 0.95;
      videoWidth = modalWidth - this.gutterSize - DEFAULT_LIVE_CHAT_WIDTH;
      videoHeight = videoWidth / this.aspectRatio();
      modalHeight = videoHeight;

      if (modalHeight > window.innerHeight) {
        modalHeight = window.innerHeight * 0.95;
        videoHeight = modalHeight;
        videoWidth = videoHeight * this.aspectRatio();
        modalWidth = videoWidth + this.gutterSize + DEFAULT_LIVE_CHAT_WIDTH;
      }

      this.modalHeight = `${modalHeight}px`;
      this.modalWidth = `${modalWidth}px`;

      this.splitDirection = SPLIT_HORIZONTAL;
      this.splitPercentVideoFeed = (videoWidth / modalWidth) * 100;
      this.splitPercentLiveChat = 100 - this.splitPercentVideoFeed;
    },

    resizeModalLandscapePortrait() {
      if (window.innerWidth < 600 || window.innerHeight < 480) {
        this.resizeModalLandscapePortraitPhone();
      } else {
        this.resizeModalLandscapePortraitDesktop();
      }
    },

    resizeModalLandscapePortraitPhone() {
      SG.debug('Resize landscape portrait, mobile');

      let modalWidth;
      let modalHeight;
      let videoWidth;
      let videoHeight;

      videoHeight = window.innerHeight;
      videoWidth = videoHeight * this.aspectRatio();
      modalWidth = videoWidth + this.gutterSize + DEFAULT_LIVE_CHAT_WIDTH;
      modalHeight = videoHeight;

      if (modalWidth > window.innerWidth) {
        modalWidth = window.innerWidth;
        videoWidth = modalWidth - this.gutterSize - DEFAULT_LIVE_CHAT_WIDTH;
        videoHeight = videoWidth / this.aspectRatio();
        modalHeight = videoHeight;
      }

      this.modalHeight = `${modalHeight}px`;
      this.modalWidth = `${modalWidth}px`;

      this.splitDirection = SPLIT_HORIZONTAL;
      this.splitPercentVideoFeed = (videoWidth / modalWidth) * 100;
      this.splitPercentLiveChat = 100 - this.splitPercentVideoFeed;
    },

    resizeModalLandscapePortraitDesktop() {
      SG.debug('Resize landscape portrait, desktop');

      let modalWidth;
      let modalHeight;
      let videoWidth;
      let videoHeight;

      videoHeight = window.innerHeight * 0.95;
      videoWidth = videoHeight * this.aspectRatio();
      modalWidth = videoWidth + this.gutterSize + DEFAULT_LIVE_CHAT_WIDTH;
      modalHeight = videoHeight;

      if (modalWidth > window.innerWidth) {
        modalWidth = window.innerWidth * 0.95;
        videoWidth = modalWidth - this.gutterSize - DEFAULT_LIVE_CHAT_WIDTH;
        videoHeight = videoWidth / this.aspectRatio();
        modalHeight = videoHeight;
      }

      this.modalHeight = `${modalHeight}px`;
      this.modalWidth = `${modalWidth}px`;

      this.splitDirection = SPLIT_HORIZONTAL;
      this.splitPercentVideoFeed = (videoWidth / modalWidth) * 100;
      this.splitPercentLiveChat = 100 - this.splitPercentVideoFeed;
    },

    resizeModalPortraitPortrait() {
      if (window.innerWidth < 600 || window.innerHeight < 480) {
        this.resizeModalPortraitPortraitPhone();
      } else {
        this.resizeModalPortraitPortraitDesktop();
      }
    },

    resizeModalPortraitPortraitPhone() {
      SG.debug('Resize portrait portrait, phone');
      this.modalWidth = `${window.innerWidth}px`;
      this.modalHeight = `${window.innerHeight}px`;
      this.splitDirection = SPLIT_VERTICAL;
      this.splitPercentVideoFeed = 66;
      this.splitPercentLiveChat = 34;
    },

    resizeModalPortraitPortraitDesktop() {
      SG.debug('Resize portrait portrait, desktop');
      let modalWidth;
      let modalHeight;
      let videoWidth;
      let videoHeight;

      modalWidth = window.innerWidth * 0.95;
      videoWidth = modalWidth - this.gutterSize - DEFAULT_LIVE_CHAT_WIDTH;
      videoHeight = videoWidth / this.aspectRatio();
      modalHeight = videoHeight;

      if (videoHeight > window.innerHeight) {
        videoHeight = window.innerHeight * 0.95;
        videoWidth = videoHeight * this.aspectRatio();
        modalWidth = videoWidth + this.gutterSize + DEFAULT_LIVE_CHAT_WIDTH;
        modalHeight = videoHeight;
      }

      this.modalHeight = `${modalHeight}px`;
      this.modalWidth = `${modalWidth}px`;

      this.splitDirection = SPLIT_HORIZONTAL;
      this.splitPercentVideoFeed = (videoWidth / modalWidth) * 100;
      this.splitPercentLiveChat = 100 - this.splitPercentVideoFeed;
    },

    resizeModalPortraitLandscape() {
      if (window.innerWidth < 600 || window.innerHeight < 480) {
        this.resizeModalPortraitLandscapePhone();
      } else {
        this.resizeModalPortraitLandscapeDesktop();
      }
    },

    resizeModalPortraitLandscapePhone() {
      SG.debug('Resize portrait landscape, phone');
      const modalWidth = window.innerWidth;
      const modalHeight = window.innerHeight;
      const videoWidth = modalWidth;
      const videoHeight = videoWidth / this.aspectRatio();

      this.modalWidth = `${modalWidth}px`;
      this.modalHeight = `${modalHeight}px`;

      this.splitDirection = SPLIT_VERTICAL;
      this.splitPercentVideoFeed = (videoHeight / modalHeight) * 100;
      this.splitPercentLiveChat = 100 - this.splitPercentVideoFeed;
    },

    resizeModalPortraitLandscapeDesktop() {
      SG.debug('Resize portrait landscape, desktop');
      let modalWidth;
      let modalHeight;
      let videoWidth;
      let videoHeight;

      videoHeight = window.innerHeight * 0.95;
      videoWidth = videoHeight * this.aspectRatio();
      modalWidth = videoWidth + this.gutterSize + DEFAULT_LIVE_CHAT_WIDTH;
      modalHeight = videoHeight;

      if (modalWidth > window.innerWidth) {
        modalWidth = window.innerWidth * 0.95;
        videoWidth = modalWidth - this.gutterSize - DEFAULT_LIVE_CHAT_WIDTH;
        videoHeight = videoWidth / this.aspectRatio();
        modalHeight = videoHeight;
      }

      this.modalWidth = `${modalWidth}px`;
      this.modalHeight = `${modalHeight}px`;

      this.splitDirection = SPLIT_HORIZONTAL;
      this.splitPercentVideoFeed = (videoWidth / modalWidth) * 100;
      this.splitPercentLiveChat = 100 - this.splitPercentVideoFeed;
    },
  },
};
