/* eslint-disable no-new */
import 'core-js';
import 'regenerator-runtime/runtime';

import axios from 'axios';
import Vue from 'vue';
import Vuex from 'vuex';

import isTrustedOrigin from 'common/origin';
import initSentry from 'common/sentry/init';
import Live from 'main/Live';
import AdminAccountBanStreaming from 'main/components/AdminAccountBanStreaming';
import OpenStreamDialog from 'main/components/OpenStreamDialog';
import OpenViewStreamDialog from 'main/components/OpenViewStreamDialog';
import TheJoinNowBanner from 'main/components/TheJoinNowBanner';
import TheLiveNowBanner from 'main/components/TheLiveNowBanner';
import UserLiveOnSgButton from 'main/components/UserLiveOnSgButton';
import { loadPlugins } from 'main/plugins';
import createStore from 'main/store';

import 'webrtc-adapter';

if (window.debugFrontend) {
  // Allow Vue devtools to be enabled, even if not dev build.
  Vue.config.devtools = true;
}

const plugins = loadPlugins(Vue);

Vue.config.productionTip = false;
initSentry(Vue);

axios.defaults.withCredentials = true;
axios.defaults.xsrfCookieName = window.csrf_cookie_name;
axios.defaults.xsrfHeaderName = 'X-CSRFToken';
axios.interceptors.request.use((config) => {
  // Only pass our XSRF token to trusted origins.
  if (isTrustedOrigin(config.url)) {
    config.withXSRFToken = true;
  } else {
    Vue.$log.warn(
      `XSRF token omitted for request to untrusted origin: ${config.url}`,
    );
  }
  return config;
});

const store = createStore({ Vuex });
new Vue({
  store,
  render: (h) => h(Live),
}).$mount('#live-dialogs');

// The Join Now Banner
const theJoinNowBanner = 'the-join-now-banner';
if (document.getElementById(theJoinNowBanner)) {
  new Vue({
    ...plugins,
    store,
    el: `#${theJoinNowBanner}`,
    components: { TheJoinNowBanner },
  });
}

// The Live Now Banner
const theLiveNowBanner = 'the-live-now-banner';
if (document.getElementById(theLiveNowBanner)) {
  new Vue({
    store,
    el: `#${theLiveNowBanner}`,
    components: { TheLiveNowBanner },
  });
}

// Open stream dialog links
Array.from(document.getElementsByClassName('open-stream-dialog'))
  .forEach((el) => {
    new Vue({
      el,
      components: { OpenStreamDialog },
      store,
    });
  });

// Open view stream dialog links
Array.from(document.getElementsByClassName('open-view-stream-dialog'))
  .forEach((el) => {
    new Vue({
      el,
      components: { OpenViewStreamDialog },
      store,
    });
  });

Array.from(document.getElementsByClassName('user-live-on-sg-button'))
  .forEach((el) => {
    new Vue({
      el,
      components: { UserLiveOnSgButton },
      store,
    });
  });

// One more elements on the admin account page
Array.from(document.getElementsByClassName('vue-admin-account'))
  .forEach((el) => {
    new Vue({
      el,
      components: { AdminAccountBanStreaming },
      store,
    });
  });

// Bind store to the window when not in production
if (ENV !== 'production') {
  window.$store = store;
}
