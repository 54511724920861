<template>
  <div id="app">
    <TheStreamDialog v-if="showStreamDialog" />
    <TheViewStreamDialog v-if="showViewStreamDialog" />
  </div>
</template>

<script>

import { mapGetters, mapMutations, mapState } from 'vuex';

import TheStreamDialog from 'main/components/TheStreamDialog';
import TheViewStreamDialog from 'main/components/TheViewStreamDialog';

export default {
  name: 'LiveDialogs',
  components: {
    TheStreamDialog,
    TheViewStreamDialog,
  },

  computed: {
    ...mapGetters([
      'allStreams',
    ]),

    ...mapState({
      currentStream: (state) => state.live.currentStream,
      showStreamDialog: (state) => state.live.showStreamDialog,
      showViewStreamDialog: (state) => state.live.showViewStreamDialog,
    }),
  },

  methods: {
    ...mapMutations([
      'CLOSE_STREAM_DIALOG',
    ]),
  },
};
</script>
