<template>
  <svg
    width="15"
    height="9"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      fill="#FFF"
      fill-rule="nonzero"
    >
      <!-- eslint-disable vue/max-len max-len -->
      <path d="M7.5 0C3.358 0 0 4.167 0 4.167s3.358 4.166 7.5 4.166c4.142 0 7.5-4.166 7.5-4.166S11.642 0 7.5 0zm0 7.676c-1.838 0-3.333-1.574-3.333-3.51C4.167 2.233 5.662.659 7.5.659s3.333 1.574 3.333 3.509c0 1.935-1.495 3.509-3.333 3.509z" />
      <path d="M9.306 3.066a.694.694 0 01-.483-1.19 2.068 2.068 0 00-.907-.21A2.086 2.086 0 005.833 3.75c0 1.149.935 2.083 2.084 2.083A2.086 2.086 0 0010 3.75c0-.318-.074-.619-.202-.889a.69.69 0 01-.492.205z" />
    </g>
  </svg>
</template>

<script>
export default {};
</script>
