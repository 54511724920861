<template>
  <div>
    <span
      class="icon-checkmark"
      title="Ban streaming"
      :class="{ checked: adminSettings.banned_from_streaming }"
      @click="toggle"
    />
    <span class="label">Ban user from streaming</span>
  </div>
</template>

<script>
import UserAdminSettings from 'main/models/UserAdminSettings';

export default {
  name: 'AdminAccountBanStreaming',

  props: {
    userId: {
      type: Number,
      required: true,
    },
  },

  computed: {
    adminSettings() {
      return UserAdminSettings.find(this.userId);
    },
    banned() {
      return this.adminSettings.banned_from_streaming;
    },
  },

  methods: {
    async toggle() {
      const method = this.banned ? 'unbanStreaming' : 'banStreaming';
      try {
        await this.adminSettings[method]();
      } catch (e) {
        SG.userError(e);
      }
    },
  },

};
</script>

<style lang="less" scoped>
.icon-checkmark.checked {
  background-color: #93b867;
}

.label {
  display: inline;
  font-family: gotham_boldregular;
  letter-spacing: 1px;
  margin: 1rem 1rem 1rem 2.5rem;
  text-transform: uppercase;
}
</style>
