<template>
  <fullscreen
    ref="fullscreen"
    class="fs-container"
    @change="fullscreenChange"
  >
    <div
      class="video-feed"
      :class="{
        fullscreen: fullscreen,
        'supermicro': supermicro,
        'micro': micro,
        'tiny': tiny,
        'small-desktop': smallDesktop,
      }"
      @click="handleUnhandledClick"
    >
      <div
        id="videowrap"
        :class="{ streaming: viewing }"
      >
        <video
          id="myvideo"
          class="rounded centered"
          autoplay
          playsinline
          @loadedmetadata="loadMetaData"
        />
        <div
          v-if="!viewing"
          class="ended"
        >
          The live stream has ended
        </div>
      </div>

      <div
        class="actions"
        :style="{ width: scaledVideoWidth }"
      >
        <button
          v-if="showActionIcons && viewing"
          class="fullscreen icon-fullscreen-v2"
          @click.stop.prevent="toggleFullscreen()"
        />
        <button
          v-if="showActionIcons"
          class="icon-close"
          @click.stop.prevent="stop()"
        />
        <button
          v-if="showActionIcons && viewing"
          class="mute"
          :class="{ muted: muted }"
          @click.stop.prevent="toggleMute()"
        >
          Mute
        </button>
        <button
          v-if="
            showActionIcons && viewing && !watchingOwnStream
              && stream.tippable && !tooSmallForTipping
          "
          class="tip"
          @click.stop.prevent="toggleTipControls"
        />

        <LiveSendTipControls
          v-if="
            viewing && showTipControls && !watchingOwnStream
              && stream.tippable && !tooSmallForTipping
          "
          :width="sendTipControlsWidth"
          :style="{ bottom: `${sendTipControlsBottom}px` }"
          @click.stop.prevent=""
          @tip-sent="showTipControls = false"
        />
      </div>
    </div>
  </fullscreen>
</template>

<script>
import axios from 'axios';
import { ViewModule } from 'streaming-module';
import Vue from 'vue';
import fullscreen from 'vue-fullscreen';
import { mapGetters, mapMutations, mapState } from 'vuex';

import LiveSendTipControls from './LiveSendTipControls/LiveSendTipControls';

import Stream from 'main/models/Stream';

Vue.use(fullscreen);

export default {
  components: {
    LiveSendTipControls,
  },

  props: {
    /**
     * Date/timestamp when the parent container was last resized.
     *
     * The date is mostly irrelevant, and it's being used mostly just so that
     * we can detect the changed dimensions if the user uses the slider or it
     * gets resized some other
     */
    lastResize: {
      type: Date,
      default: () => new Date(),
    },
    smallDesktop: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      channel: null,
      containerWidth: 0,
      containerHeight: 0,
      fullscreen: false,
      metadata: null,
      muted: false,
      pingInterval: null,
      showActionIcons: true,
      showTipControls: false,
      lastStream: null,
      viewModule: null,
      viewerToken: Math.random().toString(36).substring(7),
    };
  },

  computed: {
    allStreams() {
      return Stream.getters('allUniqueByUser');
    },

    ...mapState({
      stream: (state) => state.live.currentStream,
      viewing: (state) => state.live.isViewing,
    }),

    ...mapGetters(['currentUser']),
    ...mapGetters('browser', ['isSmallScreen']),

    /**
     * Check whether the supermicro class should be applied to this component.
     */
    supermicro() {
      let { scaledVideoWidth } = this;
      if (!scaledVideoWidth.match(/px$/)) { // Yuck
        return false;
      }
      scaledVideoWidth = Number(scaledVideoWidth.replace(/[^0-9]+$/, ''));
      return scaledVideoWidth < 200;
    },

    /**
     * Check whether the micro class should be applied to this component.
     */
    micro() {
      if (this.supermicro) {
        return false;
      }
      const isMicro = (this.containerWidth && this.containerWidth < 350)
      || (this.containerHeight && this.containerHeight < 180);
      return isMicro;
    },

    /**
     * Check whether the tiny class should be applied to this component.
     */
    tiny() {
      if (this.supermicro || this.micro) {
        return false;
      }
      const isTiny = (this.containerWidth && this.containerWidth < 400)
      || (this.containerHeight && this.containerHeight < 300);
      return isTiny;
    },

    /**
     * Determines the width that the video will be scaled to, given current
     * container dimensions and keeping the aspect ratio of the video.
     */
    scaledVideoWidth() {
      const defaultWidth = '100%';

      if (
        !this.metadata
        || !this.metadata.nativeVideoWidth
        || !this.metadata.nativeVideoHeight
      ) {
        SG.debug('no metadata');
        return defaultWidth;
      }

      if (!this.containerWidth || !this.containerHeight) {
        SG.debug('no container dimensions');
        return defaultWidth;
      }

      const videoAspectRatio = this.metadata.nativeVideoWidth
        / this.metadata.nativeVideoHeight;
      const visibleWidth = this.containerHeight * videoAspectRatio;
      const scaledVideoWidth = visibleWidth > this.containerWidth
        ? this.containerWidth : visibleWidth;

      this.$emit('calculated-width', scaledVideoWidth);

      return `${scaledVideoWidth}px`;
    },

    /**
     * Determines what the width of the send tip controls should be.
     *
     * On desktop with a large scaled video width, it will be max 575px. As
     * the scaled width of the video gets smaller, the width of the tipping
     * controls gets smaller. We try do do this smoothly, since the user
     * is able to adjust the video size using the slider.
     */
    sendTipControlsWidth() {
      const { containerWidth, scaledVideoWidth } = this;
      const scaledVideoWidthPixels = this.widthToPixels(
        scaledVideoWidth,
        containerWidth,
      );

      if (this.isSmallScreen) {
        return scaledVideoWidthPixels - 30;
      }

      if (scaledVideoWidthPixels > 800) {
        return 575;
      }

      if (scaledVideoWidthPixels > 400) {
        return scaledVideoWidthPixels - 235;
      }

      if ((scaledVideoWidthPixels - 175) >= 85) {
        return scaledVideoWidthPixels - 175;
      }

      return 0;
    },

    /**
     * Determines how far up from the bottom the video controls should be.
     *
     * When possible, they appear near the bottom, between the fullscreen and
     * mute icons. When the scaled video width is very small, however, the
     * controls don't fit between those icons and get moved up higher, about
     * the same breakpoint when it switches to stacking amount/privacy/cta
     * above each other.
     */
    sendTipControlsBottom() {
      let bottom = 20;

      if (this.containerHeight > this.containerWidth) {
        const { scaledVideoWidth } = this;
        const parsedInt = parseInt(scaledVideoWidth, 10);
        let widthInQuestion;

        if (scaledVideoWidth.includes('%')) {
          widthInQuestion = this.containerWidth * (parsedInt / 100);
        } else {
          widthInQuestion = parsedInt;
        }

        if (widthInQuestion < 485) {
          bottom = 75;
        }
      }

      return bottom;
    },

    /**
     * Checks whether the user is viewing their own stream from another window.
     */
    watchingOwnStream() {
      return (
        this.stream
        && (this.stream.user_id === this.currentUser.id)
      );
    },

    /**
     * Checks whether the current width is too small to be able to show
     * the tipping controls.
     */
    tooSmallForTipping() {
      return this.sendTipControlsWidth < 85;
    },
  },

  watch: {
    /**
     * Watch the streams in Vuex to reconnect to the new stream if a user
     * we were watching starts to stream again after a disconnection/offline
     * event.
     */
    allStreams() {
      if (this.streaming) {
        return;
      }
      // See if stream belongs to last stream
      const getStreamByUserId = Stream.getters('getStreamByUserId');
      const stream = getStreamByUserId(this.lastStream.user_id);

      if (!stream) {
        return;
      }

      this.SET_CURRENT_STREAM(stream);
      this.initStream();
      this.SET_VIEWING_TRUE(); // For Safari
    },

    lastResize() {
      this.setContainerDimensions();
    },

    /**
     * Watching viewing a.k.a isViewing from Vuex state.
     *
     * Sends pings to streamer to let them know the viewer is still watching
     * while actively viewing a stream, and clears the ping interval.
     */
    viewing() {
      if (this.viewing && !this.pingInterval) {
        this.pingInterval = setInterval(() => {
          if (!this.viewing && this.pingInterval) {
            clearInterval(this.pingInterval);
          }
          this.ping();
        }, 10000);
      } else if (this.pingInterval) {
        clearInterval(this.pingInterval);
      }

      this.toggleChannelSubscription(this.viewing);
    },
  },

  async mounted() {
    this.lastStream = this.stream;
    await this.$nextTick();
    SG.showSpinner();
    this.setContainerDimensions();
    await this.initStream();
  },

  /**
   * Before destroy lifecycle hook.
   *
   * This is being used to ensure the ping interval gets cleared, even when
   * the user doesn't explicitly close the stream.
   */
  beforeDestroy() {
    if (this.pingInterval) {
      clearInterval(this.pingInterval);
    }
  },

  methods: {
    ...mapMutations([
      'CLOSE_VIEW_STREAM_DIALOG',
      'SET_CURRENT_STREAM',
      'SET_VIEWING_TRUE',
      'SET_VIEWING_FALSE',
      'UNSET_STREAM',
    ]),

    /**
     * Initialize the stream.
     *
     * Connects to the stream via view module from streaming module.
     */
    async initStream() {
      this.viewModule = new ViewModule('agora');
      if (window.debugFrontend) {
        window.viewModule = this.viewModule;
      }
      this.$emit('initializing', this.viewModule);

      this.viewModule.init({
        remoteVideo: this.$el.querySelector('#myvideo'),
        roomName: this.stream.room_name,
        showLikes: true,
        debug: true,

        showErrorMessage(message) {
          SG.userError(message);
        },

        showInfoMessage(message) {
          SG.userMessage(message);
        },

        onStreamEnd: (isError, isClient) => {
          this.SET_VIEWING_FALSE();
          if (!isClient) {
            return;
          }
          this.CLOSE_VIEW_STREAM_DIALOG();
        },

        onStreamError: (message) => {
          SG.debug(`Stream error: ${message}`);
          SG.clearSpinner();
          this.SET_VIEWING_FALSE();

          if (message === 'Can\'t get streaming server from API...') {
            return;
          }

          SG.userError('Live streaming error');
          this.CLOSE_VIEW_STREAM_DIALOG();
        },

        onStreamInit: () => {
          this.$emit('initialized');
        },

        onVideoPlaying: () => {
          // Return if no stream is set. This is hack/workaround
          if (!this.stream) {
            return;
          }

          SG.debug('streaming starting');
          SG.clearSpinner();

          this.SET_VIEWING_TRUE();
          this.setContainerDimensions();
          this.notifyJoined();
          this.$emit('started');
        },

        onCustomDataGet: (message) => {
          this.processCustomData(message);
          this.$emit('received-custom-data', message);
        },
      });

      let response;
      try {
        response = await axios.get(this.stream.connect_url, {
          params: { stream_type: 'agora' },
        });
      } catch (e) {
        SG.clearSpinner();
        let message = 'Error connecting to stream, please try again later';
        if (e.response && e.response.status === 503) {
          message = 'Temporary connection issues, please try again later';
        }
        SG.userError(message);
        this.CLOSE_VIEW_STREAM_DIALOG();
        return;
      }
      this.viewModule.setConfig('serverData', response.data);
      this.viewModule.viewStream();
    },

    /**
     * Notify others over the websocket that we have joined the stream.
     */
    notifyJoined() {
      this.viewModule.sendCustomMessage({
        msgtype: 'data.custom',
        to: ['viewer', 'streamer'],
        data: {
          type: 'viewer.joined',
          avatar: this.currentUser.avatar,
          username: this.currentUser.username,
          viewerToken: this.viewerToken,
        },
      });
    },

    /**
     * Subscribe or unsubscribe from the Pusher websocket for the stream.
     */
    toggleChannelSubscription(connected) {
      if (connected) {
        this.channel = 'private-stream-'
          + `${this.stream.id}-${this.currentUser.id}`;
        this.$store.getters['pusher/client'].subscribe(this.channel);
      } else {
        this.$store.getters['pusher/client'].unsubscribe(this.channel);
        this.channel = null;
      }
    },

    /**
     * Send message over websocket to let the streamer know that we are still
     * here.
     */
    ping() {
      this.viewModule.sendCustomMessage({
        msgtype: 'data.custom',
        to: ['streamer'],
        data: {
          type: 'viewer.ping',
          viewerToken: this.viewerToken,
        },
      });
    },

    /**
     * Handle incoming messages being sent over the custom data channel in the
     * websocket connection to the Janus server.
     */
    processCustomData(message) {
      if (
        message.type === 'streamer.remove_user'
        && message.user_id === this.currentUser.id
      ) {
        this.stop();
      }
    },

    /**
     * Save the dimensions of the containing element to our local data.
     */
    setContainerDimensions() {
      this.containerHeight = this.$el.offsetHeight;
      this.containerWidth = this.$el.offsetWidth;
    },

    /**
     * Handle loadedmetadata event from the video.
     *
     * We listen to for this native browser event emited from the video tag
     * when the video is loaded, to get the native/non-scaled video dimensions.
     */
    loadMetaData() {
      this.metadata = {
        nativeVideoWidth: this.$el.querySelector('#myvideo').videoWidth,
        nativeVideoHeight: this.$el.querySelector('#myvideo').videoHeight,
      };
      this.$emit('loadedmetadata', this.metadata);
    },

    /**
     * Toggle fullscreen mode.
     */
    toggleFullscreen() {
      this.$refs.fullscreen.toggle();
    },

    /**
     * Handle change to fullscreen status emited by the fullscreen component.
     */
    fullscreenChange(fullscreen) { // eslint-disable-line
      SG.debug(`Fullscreen changed to ${fullscreen}`);
      this.fullscreen = fullscreen;
      this.$emit('toggled-fullscreen', fullscreen);
    },

    /**
     * Toggle muting of audio stream.
     */
    toggleMute() {
      this.muted = !this.muted;

      if (this.muted) {
        this.viewModule.muteAudio();
      } else {
        this.viewModule.unmuteAudio();
      }
    },

    /**
     * Toggle visibility of the send tip controls.
     */
    toggleTipControls() {
      if (this.showTipControls) {
        this.showTipControls = false;
      } else {
        this.showTipControls = true;

        if (this.isSmallScreen) {
          this.showActionIcons = false;
        }
      }
    },

    /**
     * Stop viewing the stream and close.
     */
    async stop() {
      if (this.fullscreen) {
        this.toggleFullscreen();
      }
      this.notifyLeft();
      this.viewModule.stopStream(false, true);
      await this.SET_VIEWING_FALSE();
      this.toggleChannelSubscription(false);
      this.CLOSE_VIEW_STREAM_DIALOG();
    },

    /**
     * Notify others over websocket that we are leaving
     * the stream.
     */
    notifyLeft() {
      this.viewModule.sendCustomMessage({
        msgtype: 'data.custom',
        to: ['viewer', 'streamer'],
        data: {
          type: 'viewer.left',
          viewerToken: this.viewerToken,
        },
      });
    },

    /**
     * Handle clicks over the video that don't fall on an icon or the tipping
     * controls.
     */
    handleUnhandledClick() {
      if (this.showTipControls) {
        this.showActionIcons = false;
        this.showTipControls = false;
      } else {
        this.showActionIcons = !this.showActionIcons;
      }
    },

    /**
      * Converts css width to pixels, for the given container size.
      */
    widthToPixels(scaledVideoWidth, containerWidth) {
      if (scaledVideoWidth.includes('%')) {
        const percent = parseInt(scaledVideoWidth, 10) / 100;
        return percent * containerWidth;
      }

      if (scaledVideoWidth.includes('px')) {
        return parseInt(scaledVideoWidth, 10);
      }

      return scaledVideoWidth;
    },
  },
};
</script>

<style scoped lang="less">
.fs-container {
  height: 100%;
  width: 100%;
}

.video-feed {
  position: relative;
  height: 100%;
  left: 0;
  top: 0;
  width: 100%;
}

&.supermicro {
  .actions {
    button.icon-close {
      font-size: 8px;
      left: 0;
      margin: 0;
      padding: 4px;
    }

    button.mute {
      bottom: 5px;
      font-size: 6px;
      height: 18px;
      padding: 4px;
      margin: 0;
      right: 5px;
    }

    button.fullscreen {
      bottom: 10px;
      left: 5px;
      margin: 0;
      padding: 0;
    }

    button.tip {
      font-size: 12px;
      right: 0;
      margin: 0;
      padding: 4px;
    }

    .live-send-tip-controls {
      display: none;
    }
  }
}

&.micro {
  .actions {
    button.icon-close {
      left: -1px;
      margin: 0;
      padding: 5px;
    }

    button.mute {
      font-size: 8px;
      height: 20px;
      margin-bottom: 10px;
      padding: 5px;
      right: 0;
    }

    button.fullscreen {
      bottom: 0;
      left: 0;
      margin: 0;
    }

    button.tip {
      font-size: 16px;
      right: 1px;
      margin: 3px;
      padding: 5px;
    }

    .live-send-tip-controls {
      display: none;
    }
  }
}

&.tiny {
  .actions {
    button.mute {
      bottom: 0;
      font-size: 12px;
      height: 31px;
      margin-bottom: 10px;
      padding: 8px;
      right: 0;
    }

    button.fullscreen {
      bottom: 10px;
      font-size: 16px;
      left: 0;
      margin: 0;
    }

    button.tip {
      margin: 8px;
    }
  }
}

&.small-desktop {
  .actions {
    button.fullscreen {
      bottom: 0;
      left: 0;
      margin-left: 0;
    }
    button.mute {
      font-size: 10px;
      padding-top: 5px;
      padding-bottom: 5px;
      padding-left: 10px;
      padding-right: 10px;
    }
  }
}

.ended {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  background: #000;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

#videowrap {
  height: 100%;
  width: 100%;
}

#myvideo {
  bottom: 0;
  height: 100%;
  left: 0;
  margin: auto;
  object-fit: contain;
  object-position: center center;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 0;

  @media @phoneLandscape {
    object-position: left top;
  }
}

.actions {
  height: 100%;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%,-50%);

  @media @phoneLandscape {
    left: 0;
    top: 0;
    transform: none;
  }

  button {
    margin: 15px;
  }

  .icon-close {
    background-color: rgba(0, 0, 0, .4);
    position: absolute;
    top: 0;
    left: 0;
    margin-left: 0;
    margin-top: 0;
  }

  .fullscreen {
    background: transparent;
    position: absolute;
    left: 0;
    bottom: 0;
    font-size: 20px;
  }

  .mute {
    background-color: rgba(0, 0, 0, .4);
    border-radius: 25px;
    bottom: 0;
    padding-left: 25px;
    padding-right: 25px;
    position: absolute;
    right: 0;
  }

  .muted {
    background-color: @darker-green;
  }

  .tip {
    background-color: transparent;
    position: absolute;
    font-size: 20px;
    top: 0;
    right: 0;
    margin: 10px;
    padding: 0;

    &:hover {
      &:before {
        color: #ffffff;
      }
    }

    &:before {
      background-color: transparent;
    }
  }

  .live-send-tip-controls {
    bottom: 20px;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
  }
}
</style>
