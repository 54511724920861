import { render, staticRenderFns } from "./TheJoinNowDefaultBanner.vue?vue&type=template&id=f3dedf76&scoped=true"
import script from "./TheJoinNowDefaultBanner.vue?vue&type=script&lang=js"
export * from "./TheJoinNowDefaultBanner.vue?vue&type=script&lang=js"
import style0 from "./TheJoinNowDefaultBanner.vue?vue&type=style&index=0&id=f3dedf76&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f3dedf76",
  null
  
)

export default component.exports