<template>
  <a
    href="#"
    @click="handleClick"
  ><slot /></a>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

export default {
  name: 'OpenViewStreamDialog',
  props: {
    stream: {
      type: Object,
      required: true,
    },
  },
  computed: mapGetters(['currentUser']),
  methods: {
    handleClick() {
      if (this.isAuthenticated()) {
        this.OPEN_VIEW_STREAM_DIALOG(this.stream);
      } else {
        window.location.href = '/join/';
      }
    },
    isAuthenticated() {
      return (
        SG && SG.user && SG.user.logged_in_username
      );
    },
    ...mapMutations(['OPEN_VIEW_STREAM_DIALOG']),
  },
};
</script>
