<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <slot name="container">
          <div class="modal-container" />
        </slot>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  mounted() {
    $('body').attr('modal', '1'); // Gross, I know.
    this.disableScrolling();
  },
  destroyed() {
    $('body').attr('modal', '0'); // Yuck. Fix me.
    this.enableScrolling();
  },
  methods: {
    enableScrolling() {
      $('body').css('overflow', 'auto');

      if (SG.isTouch()) {
        $('body').css('position', 'static');
        $('body').css('height', 'inherit');
      }
    },

    disableScrolling() {
      $('body').css('overflow', 'hidden');

      if (SG.isTouch()) {
        $('body').css('height', '100%');
        $('body').css('position', 'fixed');
      }
    },
  },
};
</script>

<style>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  transition: opacity .3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 300px;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
  transition: all .3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
