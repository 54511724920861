// From live stream module docs

function speedTester(url, name) {
  return new Promise((resolve) => {
    try {
      const a = setTimeout(
        () => resolve({
          time: 100, name, url, status: 'Failed by TIMEOUT',
        }),
        5000,
      );
      const startTime = Date.now();
      fetch(url, { mode: 'no-cors' })
        .then(() => {
          clearTimeout(a);
          const time = (Date.now() - startTime) / 1000;
          resolve({
            time, name, url, status: 'resolved',
          });
        })
        .catch((e) => {
          clearTimeout(a);
          const time = (Date.now() - startTime) / 1000;
          resolve({
            time, name, url, status: e,
          });
        });
    } catch (e) {
      resolve({
        time: 100, name, url, status: e,
      });
    }
  });
}

export default async (list = []) => {
  /**
     * Fetch a list of URLs and return the fastest name.
     *
     * Interface based on live stream data.
     */
  const responses = list.map(({ url, name }) => speedTester(url, name));
  let all = await Promise.all(responses);

  all = all.sort((a, b) => a.time - b.time);
  return all.filter((x) => x.status === 'resolved').map((x) => x.name);
};
