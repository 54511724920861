<template>
  <div class="user-info-desktop">
    <div class="watching">
      You're watching
    </div>
    <div class="details">
      <img
        :src="stream.user.avatar"
        class="avatar"
      >
      <div class="user-location-container">
        <a
          class="username"
          target="_blank"
          :href="profileUrl"
        >
          {{ stream.user.username }}
        </a>
        <div class="location">
          {{ stream.location }}
        </div>
      </div>
      <button
        v-if="!isSelf"
        class="icon-cross"
        @click="sendFollowRequest"
      />
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';

export default {
  props: {
    stream: {
      type: Object,
      required: true,
    },
  },

  computed: {
    profileUrl() {
      return `/members/${this.stream.user.username}/`;
    },
    isSelf() {
      return this.currentUser.id === this.stream.user_id;
    },
    ...mapGetters([
      'currentUser',
    ]),
  },
  methods: {
    sendFollowRequest() {
      const params = {
        user_id: this.stream.user_id,
      };
      const { username } = this.stream.user;
      axios.post('/api/following/follow/', params)
        .then((response) => {
          if (response.data.success) {
            if (response.data.is_request) {
              SG.userMessage(
                `A follow request has been sent to ${username}`,
              );
            } else {
              SG.userMessage(`You are now following ${username}`);
            }
          } else if (response.data.error === 'already friended') {
            SG.userMessage(`You are following ${username}`);
          } else {
            SG.userError(`Cannot follow ${username}`);
          }
        })
        .catch(() => {
          SG.userError(`Could not follow ${username}`);
        });
    },
  },
};
</script>

<style scoped lang="less">
.user-info-desktop {
  display: none;

  @media @overIpadLanscape {
    display: block;
  }

  .watching {
    background-color: #3d3d3d;
    color: #919191;
    font-size: 10px;
    letter-spacing: 1px;
    padding: 7px 10px 7px 10px;
    text-transform: uppercase;
  }

  .details {
    display: flex;
    padding: 20px 10px 20px 10px;
  }

  .avatar {
    border-radius: 50%;
    margin-right: 10px;
    height: 30px;
    width: 30px;
  }

  .user-location-container {
    flex-grow: 1;
  }

  .username {
    color: @pink;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 1px;
    text-transform: uppercase;
    word-break: break-all;
  }

  .location {
    color: #949494;
    font-size: 10px;
    letter-spacing: 1px;
    margin-top: 5px;
    text-transform: uppercase;
  }

  .icon-cross {
    background-color: #2c2c2c;
    color: #93b868;
    font-size: 18px;
  }
}

.narrow {
  .watching {
    font-size: 8px;
  }

  .avatar {
    height: 20px;
    width: 20px;
  }

  .username {
    font-size: 10px;
  }

  .location {
    font-size: 8px;
  }

  .icon-cross {
    font-size: 10px;
  }
}
</style>
