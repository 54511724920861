const PRIVACY_PUBLIC = 0;
const PRIVACY_MEMBERS = 1;
const PRIVACY_FOLLOWERS = 2;
const PRIVACY_PRIVATE = 3;

export default {
  data() {
    return {
      PRIVACY_PUBLIC,
      PRIVACY_MEMBERS,
      PRIVACY_FOLLOWERS,
      PRIVACY_PRIVATE,
      privacy: PRIVACY_MEMBERS,
    };
  },
};
