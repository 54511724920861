<template>
  <a
    href="#"
    @click="open()"
  ><slot /></a>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex';

import Stream from 'main/models/Stream';

export default {
  name: 'OpenStreamDialog',
  computed: mapGetters(['currentUser']),
  methods: {
    open() {
      const alreadyStreaming = Stream.query()
        .where('user_id', this.currentUser.id)
        .get().length;

      if (alreadyStreaming) {
        window.SG.userError('You are already live streaming');
        return;
      }
      this.OPEN_STREAM_DIALOG();
    },
    ...mapMutations(['OPEN_STREAM_DIALOG']),
  },
};
</script>
